import { Column } from '../components/Table/SortedFilteredTable/SortedFilteredTable';
import fromEntries from './fromEntries';

export function mapToColumns<T>(object: T, columns: Column<T>[]) {
  return fromEntries(
    columns.map((c) => {
      return [c.header, c.prop(object)];
    }),
  );
}
