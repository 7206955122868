import dayjs from 'dayjs';
import { CreateStudentDto, Student } from '../../typings/backend-types';
import { displayDateFormat } from '../dateFormat';
import { formatName } from '../formatName';
import { t } from 'i18next';
import { formatAddress } from '../formatAddress';
import uniq from 'lodash/uniq';
import { translateLicenseCategory } from '../translation/translate-license-category';

export interface CellStyle {
  fgColor?: { rgb: string };
  bgColor?: { rgb: string };
}

/**
 * converts the number excel assigns to a date to an actual Date object containing the date, month and year
 */
export function ExcelDateToJSDate(serial: number): Date | undefined {
  if (Number.isNaN(serial)) return undefined;
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  return new Date(utc_value * 1000);
}

export interface ImportStudent {
  firstName: string;
  lastName: string;
  email: string;
  billingEmail: string;
  dateOfBirth?: string;
  phone: string;
  secondaryPhone: string;
  street?: string;
  number?: string;
  zip?: string;
  city?: string;
  country?: string;
  billingStreet?: string;
  billingNumber?: string;
  billingZip?: string;
  billingCity?: string;
  billingCountry?: string;
  billingAddressee?: string;
  notes: string;
  instructorId: string;
}

export function importStudentsAreValid(
  students: ImportStudent[],
  setError: (m: string) => void,
): boolean {
  let invalidStudent: ImportStudent | undefined;
  if ((invalidStudent = students.find((s) => !s.firstName))) {
    setError('Students must have a first name');
    return false;
  }
  if ((invalidStudent = students.find((s) => !s.lastName))) {
    setError(
      `Student with first name ${invalidStudent.firstName} does not have a last name`,
    );
    return false;
  }
  if ((invalidStudent = students.find((s) => !s.email))) {
    setError(
      `Student ${invalidStudent.firstName} ${invalidStudent.lastName} does not have an email address`,
    );
    return false;
  }
  if ((invalidStudent = students.find((s) => !s.phone))) {
    setError(
      `Student ${invalidStudent.firstName} ${invalidStudent.lastName} does not have a phone number`,
    );
    return false;
  }
  if ((invalidStudent = students.find((s) => !s.instructorId))) {
    setError(
      `Student ${invalidStudent.firstName} ${invalidStudent.lastName} does not have an instructor`,
    );
    return false;
  }
  return true;
}

export function studentToExportStudent(
  s: Student,
): Record<string, string | number | (string | undefined)[] | undefined | null> {
  const translate = (key: string) => t(`students.${key}`);

  return {
    [translate('studentNumber')]: s.student_number,
    [translate('firstName')]: s.firstName,
    [translate('lastName')]: s.lastName,
    [translate('balance')]: s.balance,
    [translate('email')]: s.email,
    [translate('billingEmail')]: s.billingEmail,
    [translate('phone')]: s.phone,
    [translate('secondaryPhone')]: s.secondaryPhone,
    [translate('notes')]: s.notes,
    [translate('groups')]: s.groups ? s.groups.map((g) => g.title) : null,
    [translate('studentDetail.licenseCategories')]: s.syllabusProgressCards
      ? uniq(
          s.syllabusProgressCards.map((g) =>
            translateLicenseCategory(g.syllabus?.licenseCategory),
          ),
        )
      : null,
    [translate('instructor')]: formatName(s.instructor) || null,
    [translate('dateOfBirth')]: s.dateOfBirth
      ? dayjs(s.dateOfBirth).format(displayDateFormat)
      : '',
    [translate('createdDate')]: s.createdDate
      ? dayjs(s.createdDate).format(displayDateFormat)
      : '',
    [translate('primaryAddress')]: formatAddress(s.primaryAddress),
    [translate('billingAddress')]: formatAddress(s.billingAddress),
  };
}

export function importStudentToStudentDto(s: ImportStudent): CreateStudentDto {
  return {
    ...s,
    dateOfBirth:
      ExcelDateToJSDate(Number(s.dateOfBirth))?.toISOString() ??
      dayjs(s.dateOfBirth, displayDateFormat).toISOString(),
    primaryAddress: {
      street: s.street,
      number: s.number,
      zip: s.zip,
      city: s.city,
      country: s.country,
    },
    billingAddress: {
      street: s.billingStreet,
      number: s.billingNumber,
      zip: s.billingZip,
      city: s.billingCity,
      country: s.billingCountry,
      addressee: s.billingAddressee,
    },
    instructor: { id: s.instructorId },
  };
}
