import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useAppContext } from '../../context/appContext';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { DashboardComponent, Student } from '../../typings/backend-types';
import { displayDateFormat } from '../../utilities/dateFormat';
import { formatCurrency } from '../../utilities/formatCurrency';
import { formatName } from '../../utilities/formatName';
import { Routes } from '../../utilities/routes';
import { Panel } from '../Common/Panel';
import { PagedTable } from '../Table/PagedTable';
import { Column } from '../Table/SortedFilteredTable/SortedFilteredTable';
import { SectionHeading } from '../Typography/SectionHeading';
import { DynamicExportButton } from '../Common/ExportImport/DynamicExportButton';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { studentToExportStudent } from '../../utilities/students/export-import-helpers';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';

export const MyStudentsTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.myDrivingStudents',
  });
  const { userId } = useAppContext();
  const { setErrorMessage } = useErrorPopupContext();
  const fetchUrl = () =>
    HttpEndpoints.StudentEndpoints.getStudentsForInstructor(userId, {
      archived: false,
    });

  const { data: students } = useSWR<Student[]>(fetchUrl, authenticatedFetcher, {
    fallbackData: [],
  });

  const myStudentsColumns: Column<Student>[] = [
    {
      header: t('studentNameColumn'),
      prop: formatName,
    },
    {
      header: t('dateOfBirthColumn'),
      prop: (student: Student) =>
        student.dateOfBirth &&
        dayjs(student.dateOfBirth).format(displayDateFormat),
    },
    {
      header: t('balanceColumn'),
      prop: (student: Student) => formatCurrency(student.balance),
    },
  ];

  const canSeeOwnStudents = useUserPermission(
    Permission.OWN_STUDENTS_INFO,
    Scope.READ,
  );

  if (!canSeeOwnStudents) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.MY_STUDENTS}
      />
    );
  }

  return (
    <Panel className="break-inside-avoid">
      <div className="flex overflow-visible">
        <SectionHeading className="flex-grow">
          {t('tableHeader')}
        </SectionHeading>
        <DynamicExportButton
          small
          data={students.map(studentToExportStudent)}
          exportFileName={t('tableHeader')}
        />
      </div>
      <PagedTable
        fetchUrl={fetchUrl}
        columns={myStudentsColumns}
        limit={5}
        syncPageWithQueryParamKey="myStudentsPage"
        onError={() => setErrorMessage(t('couldNotFetchMessage'))}
        altText={t('tableAltText')}
        showSearch={false}
        rowLink={({ id }) => Routes.Students.DetailView(id)}
      />
    </Panel>
  );
};
