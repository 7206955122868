import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useAppContext } from '../../context/appContext';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { DashboardComponent, Student } from '../../typings/backend-types';
import { formatName } from '../../utilities/formatName';
import { mapToColumns } from '../../utilities/mapToColumns';
import { Routes } from '../../utilities/routes';
import { Panel } from '../Common/Panel';
import { PagedTable } from '../Table/PagedTable';
import { Column } from '../Table/SortedFilteredTable/SortedFilteredTable';
import { SectionHeading } from '../Typography/SectionHeading';
import { DynamicExportButton } from '../Common/ExportImport/DynamicExportButton';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { formatNameWithLabel } from '../../utilities/students/formatNameWithLabel';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';

export const AllStudentsWithoutAppointmentTile: FunctionComponent = () => {
  const { organizationId } = useAppContext();
  const { setErrorMessage } = useErrorPopupContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.studentsWithoutAppointment',
  });

  const canExportStudents = useUserPermission(
    Permission.STUDENT_EXPORT,
    Scope.READ,
  );

  const fetchUrl = () =>
    HttpEndpoints.StudentEndpoints.getStudentsForOrganization(organizationId, {
      withoutAppointment: true,
      includeBalance: false,
      archived: false,
    });

  const { data: studentsWithoutAppointment } = useSWR<Student[]>(
    fetchUrl,
    authenticatedFetcher,
    { fallbackData: [] },
  );

  const allStudentsWithoutAppointmentColumns: Column<Student>[] = [
    {
      header: t('studentNameColumn'),
      prop: (student: Student) => formatNameWithLabel(student),
    },
    {
      header: t('instructorColumn'),
      prop: (student: Student) => formatName(student.instructor),
    },
  ];

  const canSeeAllStudents = useUserPermission(
    Permission.ALL_STUDENTS_INFO,
    Scope.READ,
  );

  if (!canSeeAllStudents) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.ALL_STUDENTS_WITHOUT_APPOINTMENT}
      />
    );
  }

  return (
    <Panel className="break-inside-avoid">
      <div className="flex items-center">
        <SectionHeading className="flex-grow mb-0 mr-3">
          {t('allStudentsTableHeader')}
        </SectionHeading>
        {canExportStudents && (
          <DynamicExportButton
            small
            data={studentsWithoutAppointment.map((student) =>
              mapToColumns(student, allStudentsWithoutAppointmentColumns),
            )}
            exportFileName={t('allStudentsTableHeader')}
          />
        )}
      </div>

      <PagedTable
        fetchUrl={fetchUrl}
        columns={allStudentsWithoutAppointmentColumns}
        onError={() => setErrorMessage(t('couldNotFetchMessage'))}
        limit={5}
        syncPageWithQueryParamKey="studentsWithoutAppointmentPage"
        altText={t('tableAltText')}
        showSearch={false}
        rowLink={({ id }) => Routes.Students.DetailView(id)}
      />
    </Panel>
  );
};
